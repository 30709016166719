import React from "react";

export interface JumboDividerProps {
    color?: string,
    height?: string,
}

const JumboDivider: React.FunctionComponent<JumboDividerProps> = function (props) {
    let style: React.CSSProperties = {
        height: "3em",
    };
    if (props.color) {
        style.backgroundColor = props.color;
    }
    if (props.height) {
        style.height = props.height;
    }
    return (
        <div style={style}>
        </div>
    )
}

export default JumboDivider;
