
export const parseQueryParams = function (queryString) {
    let query = {};
    if (!queryString) return query;
    let pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
    for (let i = 0; i < pairs.length; i++) {
        let pair = pairs[i].split('=');
        query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }
    return query;
}

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
export function debounce(func, wait, immediate) {
    let timeout;
    return function () {
        let context = this, args = arguments;
        let later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        let callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
};

/**
 * Combine classNames from multiple sources into a single string.
 * @param  {...any} classNames Any number of strings of classNames, nulls or undefined
 */
export const cn = function (...classNames) {
    let className = "";
    for (let idx in classNames) {
        const name = classNames[idx];
        if (name) {
            className += ` ${name}`;
        }
    }
    return className.trim();
}
