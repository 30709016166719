import React, { CSSProperties } from 'react';

export interface ColProps {
    width?: string | number,
    size?: number,
    xs?: boolean,
    s?: boolean,
    m?: boolean,
    l?: boolean,
    xl?: boolean,

    xs1?: boolean, xs2?: boolean, xs3?: boolean, xs4?: boolean, xs5?: boolean, xs6?: boolean,
    xs7?: boolean, xs8?: boolean, xs9?: boolean, xs10?: boolean, xs11?: boolean, xs12?: boolean,
    s1?: boolean, s2?: boolean, s3?: boolean, s4?: boolean, s5?: boolean, s6?: boolean,
    s7?: boolean, s8?: boolean, s9?: boolean, s10?: boolean, s11?: boolean, s12?: boolean,
    m1?: boolean, m2?: boolean, m3?: boolean, m4?: boolean, m5?: boolean, m6?: boolean,
    m7?: boolean, m8?: boolean, m9?: boolean, m10?: boolean, m11?: boolean, m12?: boolean,
    l1?: boolean, l2?: boolean, l3?: boolean, l4?: boolean, l5?: boolean, l6?: boolean,
    l7?: boolean, l8?: boolean, l9?: boolean, l10?: boolean, l11?: boolean, l12?: boolean,
    xl1?: boolean, xl2?: boolean, xl3?: boolean, xl4?: boolean, xl5?: boolean, xl6?: boolean,
    xl7?: boolean, xl8?: boolean, xl9?: boolean, xl10?: boolean, xl11?: boolean, xl12?: boolean,

    hideXs?: boolean,
    hideS?: boolean,
    hideM?: boolean,
    hideL?: boolean,

    orderXs?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12,
    orderS?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12,
    orderM?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12,
    orderL?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12,
    orderXl?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12,

    align?: "top" | "center" | "bottom",
    justify?: "left" | "center" | "right",
    className?: string,
    style?: CSSProperties,
    grow?: number,
}

const sizesExtraSmall = [
    "xs1", "xs2", "xs3", "xs4", "xs5", "xs6",
    "xs7", "xs8", "xs9", "xs10", "xs11", "xs12",
];
const sizesSmall = [
    "s1", "s2", "s3", "s4", "s5", "s6",
    "s7", "s8", "s9", "s10", "s11", "s12",
];
const sizesMedium = [
    "m1", "m2", "m3", "m4", "m5", "m6",
    "m7", "m8", "m9", "m10", "m11", "m12",
];
const sizesLarge = [
    "l1", "l2", "l3", "l4", "l5", "l6",
    "l7", "l8", "l9", "l10", "l11", "l12",
];
const sizesExtraLarge = [
    "xl1", "xl2", "xl3", "xl4", "xl5", "xl6",
    "xl7", "xl8", "xl9", "xl10", "xl11", "xl12",
];
const sizes = [
    { name: "xs", value: "", sizes: sizesExtraSmall },
    { name: "s", value: "-sm", sizes: sizesSmall },
    { name: "m", value: "-md", sizes: sizesMedium },
    { name: "l", value: "-lg", sizes: sizesLarge },
    { name: "xl", value: "-xl", sizes: sizesExtraLarge },
];
const hide = [
    { name: "hideXs", hide: "d-none", show: "" },
    { name: "hideS", hide: "d-sm-none", show: "d-sm-block" },
    { name: "hideM", hide: "d-md-none", show: "d-md-block" },
    { name: "hideL", hide: "d-lg-none", show: "d-lg-block" },
];
const orders = [
    { name: "orderXs", order: "order-" },
    { name: "orderS", order: "order-sm-" },
    { name: "orderM", order: "order-md-" },
    { name: "orderL", order: "order-lg-" },
    { name: "orderXl", order: "order-xl-" },
];

const Col: React.FunctionComponent<ColProps> = function (props) {
    const anyProps: any = props;
    let style = props.style || {};
    let className = "col";
    if (typeof props.width === "string") {
        style.maxWidth = props.width;
    } else if (typeof props.width === "number") {
        style.maxWidth = props.width + "px";
    } else if (typeof props.size === "number" && props.size > 0 && props.size <= 12) {
        className += " col-" + props.size;
    } else {
        sizes.forEach((sizeType) => {
            if (typeof anyProps[sizeType.name] === "boolean") {
                className += " col" + sizeType.value;
            } else {
                let s = sizeType.sizes.findIndex((size) => (size in props && typeof anyProps[size] === "boolean"));
                if (s >= 0) {
                    className += " col" + sizeType.value + "-" + (s + 1);
                }
            }
        });
    }
    orders.forEach((orderType) => {
        if (typeof anyProps[orderType.name] === "number") {
            className += ` ${orderType.order}${anyProps[orderType.name]}`
        }
    });
    if (props.align) {
        switch (props.align) {
            case "top": className += " align-self-start"; break;
            case "center": className += " align-self-center"; break;
            case "bottom": className += " align-self-end"; break;
        }
    }
    if (props.justify) {
        switch (props.justify) {
            case "left": style.textAlign = "start"; break;
            case "center": style.textAlign = "center"; break;
            case "right": style.textAlign = "end"; break;
        }
    }
    if (typeof props.grow === "number") {
        style.flexGrow = props.grow;
    }
    let hideIdx = hide.findIndex((hide) => (hide.name in props && (props as any)[hide.name]));
    if (hideIdx >= 0) {
        className += " d-none";
        if (hideIdx + 1 < hide.length) {
            className += " " + hide[hideIdx + 1].show;
        }
    }
    if (props.className) {
        className += " " + props.className;
    }
    return (
        <div className={className} style={style}>
            {props.children}
        </div>
    )
}

export default Col;
