import React from "react";
import Image from "gatsby-image";

import { withTranslate } from "../../components/translate";
import Row from "../../components/common/Row";
import Col from "../../components/common/Col";
import ScrollToTopOnMount from "../../components/common/ScrollToTopOnMount";
import NavBar from "../../components/common/NavBar";
import Jumbotron from '../../components/common/Jumbotron';

import "./BlogsPage.css";
import Link from "../../components/common/Link";
import JumboDivider from "../../components/common/JumboDivider";
import SEO from "../../components/SEO";

const Blog = function ({ blog }) {
    return (
        <Col xs12 m6 l4>
            <Link to={"/blog/" + blog.slug}>
                <div className="blog">
                    <Image
                        fluid={blog.image}
                        alt={blog.title}
                        className="img"
                    />
                    <div className="blog-text">
                        <div className="author">
                            <img
                                className="author-icon"
                                src={blog.authorIcon}
                                alt={blog.author}
                            />
                            {blog.author}<span className="reading-time">{blog.readingTime} min</span>
                        </div>
                        <h2>{blog.title}</h2>
                        <p>{blog.snippet}</p>
                    </div>
                </div>
            </Link>
        </Col>
    )
}

const BlogsPage = withTranslate(function ({ translate, blogs }) {
    return (
        <div className="blogs-page">
            <SEO
                title={translate("blogs.head.title")}
                description={translate("blogs.head.description")}
                path="/blog"
            />
            <ScrollToTopOnMount />
            <NavBar translate={translate} />
            <header className="container pt-3 pb-2">
                <div className="intro mt-3">
                    <h1>
                        {translate("blogs.tagline.title")}
                    </h1>
                    <p className="h2-sub">
                        {translate("blogs.tagline.subtitle")}
                    </p>
                </div>
            </header>
            <JumboDivider color="#FFC107"/>
            <Jumbotron color="#fff">
                <Row justify="center">
                    {blogs.map((blog) => (<Blog key={blog.slug} blog={blog} />))}
                </Row>
            </Jumbotron>
        </div>
    )
});

export default BlogsPage;
