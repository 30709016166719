import React from "react"
import { graphql } from "gatsby";

import Layout from "../components/Layout"
import BlogsPage from "../views/BlogsPage"
import { DEFAULT_LOCALE } from "../translations";

export const query = graphql`
{
    allBlogsJson(sort: { fields: date, order: DESC }) {
      edges {
        node {
          slug
          date
          taglineImage {
            childImageSharp {
              fluid(maxWidth: 350, quality: 80) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          authorIcon {
              childImageSharp {
                  original {
                      src
                  }
              }
          }
          author
          readingTime
          title {
            locale
            text
          }
          snippet {
            locale
            text
          }
        }
      }
    }
}
`;

const matchLocale = function (locale) {
    if (!locale) {
        locale = DEFAULT_LOCALE;
    }
    return (item) => (item.locale === locale);
}

const Blogs = ({ location, data, pageContext: { locale } }) => (
    <Layout location={location} locale={locale}>
        <BlogsPage
            blogs={data.allBlogsJson.edges.map((edge) => ({
                slug: edge.node.slug,
                authorIcon: edge.node.authorIcon.childImageSharp.original.src,
                author: edge.node.author,
                readingTime: edge.node.readingTime,
                image: edge.node.taglineImage.childImageSharp.fluid,
                title: edge.node.title.find(matchLocale(locale)).text,
                snippet: edge.node.snippet.find(matchLocale(locale)).text,
            }))}
        />
    </Layout>
);

export default Blogs;
