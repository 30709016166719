import React from "react";
import { cn } from "../../util";

const Jumbotron = function (props) {
    let style = { ...props.style }
    if (props.color) {
        style.backgroundColor = props.color;
    }
    let className = "jumbotron";
    if (props.className) {
        className += " " + props.className;
    }
    return (
        <section className={className} style={style}>
            <div className={cn("container", props.containerClassName)}>
                {props.children}
            </div>
        </section>
    );
};

export default Jumbotron;
